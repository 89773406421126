import { Main } from "./components/js/main"
import { Header } from "./components/js/header"
import './App.css';

export default function App() {
  return (
    <>

      <Header />
      <Main />
     

    </>
  )
}